import { FormsFieldPreset, FormPlugin, UpgradeAlertType, BillingPanelReferrer } from '@wix/forms-common'

export type PremiumRestriction = {
  forms: { limit: number; threshold?: number }
  emails: { limit: number; threshold?: number }
  fields: { limit: number; threshold?: number }
  submissions: { limit: number; threshold?: number }
  rules: { limit: number; threshold?: number }
  steps: { limit: number; threshold?: number }
  allowedFields: {
    uploadButton?: boolean
    generalUploadButton?: boolean
    generalSignature?: boolean
  }
  allowedRedirections: { downloadFile?: boolean }
  allowedPlugins: { [FormPlugin.PAYMENT_FORM]?: boolean }
  isTopPremium?: boolean
}

export type Feature = {
  id: string
  uniqueName: string
  quotaFeature?: {
    limit: number
  }
  enabled: boolean
}

export const DEFAULT_RESTRICTIONS: PremiumRestriction = {
  forms: { limit: 5, threshold: 3 },
  fields: { limit: 10, threshold: 7 },
  rules: {limit: 3, threshold: 2 },
  submissions: { limit: 100, threshold: 70 },
  steps: { limit: 3, threshold: 2 },
  emails: { limit: 2, threshold: 2 },
  allowedRedirections: {},
  allowedFields: {},
  allowedPlugins: {},
}

export const getAscendPackagePickerUrl = (msid, appDefId, origin, vertical: string): string =>
  `https://www.wix.com/apps/upgrade?metaSiteId=${msid}&appDefId=${appDefId}&pp_origin=${origin}_${vertical}`

export const getAscendPackagePickerNewUrl = (
  msid: string,
  origin: string,
  vertical: string
): string =>
  `https://www.wix.com/ascend/plans?pp_origin=${origin}_${vertical}&metaSiteId=${msid}&originAppSlug=wix-forms`

export const referrerByAlertType = {
  [UpgradeAlertType.FORMS_LIMIT]: BillingPanelReferrer.NUMBER_OF_FORMS_ALERT,
  [UpgradeAlertType.FIELDS_LIMIT]: BillingPanelReferrer.NUMBER_OF_FIELDS_ALERT,
  [UpgradeAlertType.FIELDS_DUPLICATE_LIMIT]: BillingPanelReferrer.DUPLICATE_FIELD_ALERT,
  [UpgradeAlertType.STEPS_LIMIT]: BillingPanelReferrer.NUMBER_OF_STEPS_ALERT,
  [UpgradeAlertType.STEPS_DUPLICATE_LIMIT]: BillingPanelReferrer.NUMBER_OF_STEPS_ALERT,
  [UpgradeAlertType.UPLOAD_BUTTON]: BillingPanelReferrer.UPLOAD_FIELD_ALERT,
  [UpgradeAlertType.DOWNLOAD_REDIRECT]: BillingPanelReferrer.DOWNLOAD_REDIRECT_ALERT,
  [FormsFieldPreset.GENERAL_UPLOAD_BUTTON]: BillingPanelReferrer.UPLOAD_FIELD_ALERT,
  [UpgradeAlertType.EMAILS_LIMIT]: BillingPanelReferrer.NUMBER_OF_EMAILS_ALERT,
}
