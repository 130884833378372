import { FormsFieldPreset, FIELD_COMPONENT_TYPES, FormPlugin, FieldCollectionType, FieldRenderConfigType } from '@wix/forms-common'
import { FIELDS, ROLE_FORM } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import translations from '../../../../../../utils/translations'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from '../../../../manifests/manifest-commons'

export class GeneralRecaptcha extends IGeneralField {
  public get customFields() {
    return []
  }

  protected get icon() {
    return iconNames.recaptcha
  }

  public get acceptConditions() {
    return false
  }

  public get fieldType() {
    return FormsFieldPreset.GENERAL_RECAPTCHA
  }

  public get collectionFieldType() {
    return FieldCollectionType.BOOLEAN
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.RECAPTCHA
  }

  public get role() {
    return FIELDS.ROLE_FIELD_RECAPTCHA
  }

  protected get props() {
    return {
      type: 'CaptchaProperties',
      theme: 'light',
      size: 'normal',
      captchaType: 'image',
      required: true
    }
  }

  protected get skin() {
    return 'wixui.skins.Captcha'
  }

  protected get layout() {
    return {
      width: 234,
      height: 60,
    }
  }

  protected get data() {
    return {
      type: 'Captcha',
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        isEditable: false,
        required: FieldRenderConfigType.REMOVE,
        crmSync: FieldRenderConfigType.REMOVE,
        addFieldPanelData: {
          category: DEFAULT_CATEGORIES.recaptcha,
          hideTranslationPostfix: true,
        }
      }
    }
  }

  public get allowOnlyOnce() {
    return true
  }

  public get disableCollectionSync() {
    return true
  }

  public get base() {
    return GeneralRecaptcha.prototype
  }

  public get manifest() {
    return {
      relatedToRole: ROLE_FORM,
      displayName: translations.t('fields.labelName'),
      behavior: { duplicatable: false },
      gfpp: {
        desktop: {
          mainAction1: GFPP.REMOVE,
          mainAction2: {
            actionId: GFPP_IDS.RECAPTCHA_HELP,
            label: translations.t('manifest.captcha.learnMore'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
            [GFPP_ACTIONS.LAYOUT]: GFPP.REMOVE,
            [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
            [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
          },
          helpId: GFPP.HELP_ID.CAPTCHA,
        },
        mobile: {
          helpId: GFPP.HELP_ID.CAPTCHA,
        },
      },
    }
  }

  protected get styleProperties() {
    return {
      'properties': {},
    }
  }
}
